import React from "react";
import {Heading, Link, List, ListItem, Stack, Text} from "@chakra-ui/react";

export const BlogPost1 = () => {
    return (
        <Stack spacing={3}>
            <Text>
                Deciding between windshield repair and replacement in Gilbert, AZ, involves understanding local factors
                such as climate and road conditions. It's important to make an informed choice for your vehicle’s auto
                glass needs.
            </Text>
            <Heading as="h3" fontSize={'xl'}>Windshield Repair or Replacement: Making the Right Choice in
                Gilbert</Heading>
            <List styleType="disc">
                <ListItem>Examine the extent of the damage. Smaller chips might only require <Link
                    href="/windshield-repair">repair</Link>, while larger cracks may need a full <Link
                    href="/windshield-replacement">replacement</Link>.</ListItem>
                <ListItem>Evaluate the damage location. If the crack impairs the driver's view or is near the
                    windshield's edge, replacement is often necessary.</ListItem>
                <ListItem>Consider safety implications. A compromised windshield can affect the vehicle's structural
                    integrity and safety.</ListItem>
                <ListItem>Check your insurance policy. Coverage may vary based on whether the windshield needs repair or
                    replacement.</ListItem>
            </List>
            <Text>For professional guidance on whether to repair or replace your windshield in Gilbert, contact <Link
                href="/">AutoGlassRepairGilbert.com</Link>.</Text>
        </Stack>
    )
}