import React from "react";
import {Heading, Link, List, ListItem, Stack, Text} from "@chakra-ui/react";

export const BlogPost3 = () => {
    return (
        <Stack spacing={3}>
            <Text>
                Mobile auto glass services offer unparalleled convenience and efficiency for residents in Gilbert, AZ.
                Discover how these services cater to your lifestyle without compromising on quality.
            </Text>
            <Heading as="h3" fontSize={'xl'}>The Advantages of Mobile Auto Glass Services in Gilbert</Heading>
            <List styleType="disc">
                <ListItem>On-the-Spot Repairs: Get your auto glass repaired or replaced wherever you are in Gilbert – be
                    it at home, work, or on the road.</ListItem>
                <ListItem>Time-Saving: Mobile services save you time by eliminating the need to visit a service
                    center.</ListItem>
                <ListItem>Quality Assurance: Despite being mobile, the quality of repair and replacement remains
                    top-notch, adhering to high standards.</ListItem>
            </List>
            <Text>Experience the convenience of mobile auto glass services in Gilbert. Contact <Link
                href="/">AutoGlassRepairGilbert.com</Link> for efficient and reliable service.</Text>
        </Stack>
    )
}