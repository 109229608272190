import React from "react";
import autoglassRepairGilbertMeta from '../assets/images/meridenmeta.jpg'
import {posts} from "./posts";
import {Box, Button, Flex, Heading, Hide, Link, List, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";
import {BiSupport, BiWrench} from "react-icons/bi";
import {RiDashboardLine, RiEmotionHappyLine} from "react-icons/ri";
import {FaCarCrash, FaTools} from "react-icons/fa";
import {AiOutlineCar} from "react-icons/ai";
import {MdDirectionsCar, MdOutlineSpeed} from "react-icons/md";
import {GiCarDoor} from "react-icons/gi";

const phoneNumber = '(480) 771-9851';
const telLink = 'tel:4807719851';
const phoneNumberWithDashes = '480-771-9851';

export const SiteData = {
    hoursOfOperation: "Monday to Saturday, 8:00 AM - 6:00 PM",
    city: 'Gilbert, AZ',
    phoneNumber,
    phoneNumberWithDashes,
    telLink,
    buttonCtaText: () => <Flex>Experiencing Auto Glass Issues in Gilbert? Click Here for a&nbsp;<Text
        textDecoration={'underline'} display={'inline'}>Free Quote!</Text></Flex>,
    keywords: 'Auto Glass Repair Gilbert AZ, windshield replacement Gilbert, car window repair Gilbert, vehicle glass services Gilbert.',
    footerText: 'Auto Glass Repair Gilbert, All rights reserved.',
    ogImage: autoglassRepairGilbertMeta,
    navLinks: [
        {
            title: 'Home',
            href: '/'
        },
        {
            title: 'Services',
            href: '/services'
        },
        {
            title: 'Blog',
            href: '/blog'
        },
        {
            title: 'About',
            href: '/about'
        },
        {
            title: 'Contact Us',
            href: '/contact'
        },
        {
            title: 'Careers',
            href: '/careers',
            hideFromTopNav: true
        },
    ],
    signupForm: {
        intro: () => <Text>Welcome to Auto Glass Repair Gilbert, the leading auto glass repair service in Gilbert.
            Specializing in windshield repair and replacements, we’re committed to providing quality service for every
            vehicle. Call <Link href={telLink} textDecoration={'underline'}>{phoneNumber}</Link> for a free,
            no-obligation quote.</Text>,
        servicesTitle: 'Our Services:',
    },
    homepage: {
        url: 'https://www.autoglassrepairgilbert.com',
        metaData: {
            title: 'Expert Auto Glass Repair in Gilbert, AZ - Auto Glass Repair Gilbert',
            description: 'Professional auto glass repair and windshield replacement in Gilbert, AZ. Quality service, advanced technology, and customer satisfaction guaranteed.'
        },
        h1: 'Reliable Auto Glass Repair Services in Gilbert, AZ',
        subtitle: 'Your Trusted Partner for Windshield Repair and Replacement',
        servicesTitle: 'Comprehensive Auto Glass Solutions',
        values: [
            {
                id: 0,
                number: "10+ Years",
                name: "Experience in Glass Repair",
                icon: FaTools,
            },
            {
                id: 1,
                number: "100%",
                name: "Customer Satisfaction",
                icon: RiEmotionHappyLine,
            },
            {
                id: 2,
                number: "Same Day",
                name: "Fast and Reliable Repairs",
                icon: MdOutlineSpeed,
            },
            {
                id: 3,
                number: "24/7",
                name: "Emergency Services",
                icon: BiSupport,
            }
        ],
        services: [
            {
                id: '0',
                text: () => <Text><b>Comprehensive Windshield Repair:</b> Specializing in repairing chips and cracks to
                    restore your windshield's integrity and clarity, ensuring your safety on the roads of Gilbert,
                    AZ.</Text>
            },
            {
                id: '1',
                text: () => <Text><b>Windshield Replacement:</b> Offering high-quality windshield replacements for all
                    types of vehicles. We ensure a perfect fit and superior visibility, crucial for the varying weather
                    conditions in Gilbert.</Text>
            },
            {
                id: '2',
                text: () => <Text><b>Mobile Auto Glass Services:</b> Bringing our expertise to your doorstep. Our mobile
                    service in Gilbert, AZ, provides convenient auto glass repairs wherever you are, whenever you need
                    it.</Text>
            },
            {
                id: '3',
                text: () => <Text><b>Rear Window Repair and Replacement:</b> Specialized services for your vehicle’s
                    rear window, providing both repair and full replacement solutions, ensuring safety and visibility
                    from all angles.</Text>
            },
            {
                id: '4',
                text: () => <Text><b>Side Window Repair and Replacement:</b> Addressing side window damages with
                    efficiency and precision. Whether it’s a minor chip or a full replacement, we ensure the security
                    and aesthetics of your vehicle are maintained.</Text>
            },
            {
                id: '5',
                text: () => <Text><b>ADAS Calibration:</b> Advanced Driver Assistance Systems require precise
                    calibration for optimal functionality. Post-repair or replacement, we ensure these critical systems
                    are accurately aligned and functional.</Text>
            },
            {
                id: '6',
                text: () => <Text><b>Emergency Auto Glass Services:</b> Available 24/7 for any auto glass emergencies in
                    Gilbert, AZ. Our rapid response team is ready to provide immediate, reliable solutions to secure
                    your vehicle and safety.</Text>
            }
        ],
        featuredServices: [
            {
                name: 'Windshield Repair',
                description: 'Expert repair services for chips and cracks, enhancing your windshield’s durability and clarity. Utilizing advanced resin injection techniques for a seamless finish.',
                icon: BiWrench,
                path: '/windshield-repair',
                linkTitle: 'Windshield Repair'
            },
            {
                name: 'Windshield Replacement',
                description: 'Comprehensive windshield replacement for all vehicle types. We use high-quality glass and adhesives, ensuring a perfect fit and long-lasting results.',
                icon: FaCarCrash,
                path: '/windshield-replacement',
                linkTitle: 'Windshield Replacement'
            },
            {
                name: 'Mobile Auto Glass Services',
                description: 'Convenience at your doorstep with our mobile auto glass repair and replacement services. Ideal for busy schedules or emergency situations in Gilbert, AZ.',
                icon: MdDirectionsCar,
                path: '/mobile-auto-glass-services',
                linkTitle: 'Mobile Auto Glass Services'
            },
            {
                name: 'Rear Window Repair',
                description: 'Specialized services for rear window issues, providing both repair and full replacement options based on your vehicle’s needs.',
                icon: GiCarDoor,
                path: '/rear-window-repair',
                linkTitle: 'Rear Window Repair'
            },
            {
                name: 'Side Window Repair',
                description: 'Addressing side window damages efficiently. Whether it’s a minor repair or a full replacement, we ensure top-notch service and safety.',
                icon: AiOutlineCar,
                path: '/side-window-repair',
                linkTitle: 'Side Window Repair'
            },
            {
                name: 'ADAS Calibration',
                description: 'Advanced Driver Assistance Systems (ADAS) calibration post windshield replacement, ensuring your vehicle’s safety features function correctly.',
                icon: RiDashboardLine,
                path: '/adas-calibration',
                linkTitle: 'ADAS Calibration'
            }
        ],
        content: [
            <Box>
                <Text>
                    <Heading as="h2" size={'md'} pb='2'>Gilbert's Premier Experts in Auto Glass Repair</Heading>
                    Welcome to Gilbert's leading destination for auto glass repair, Auto Glass Repair Gilbert! Situated
                    in the vibrant city of Gilbert, AZ, we are your reliable solution for all auto glass needs. From
                    small chips and <a href="https://www.belmontautoglasspro.com/"
                                       target={'_blank'} rel={'noreferrer'}>windsheild repair</a> to complete windshield
                    replacements, our team is renowned for swift response,
                    exceptional service, and competitive pricing. We do more than just fix glass; our mission is to
                    restore your safety and peace of mind while driving.

                    Be it an emergency repair or a routine replacement, our experts come equipped with the latest
                    technology and extensive knowledge, ready to tackle your auto glass problems with efficiency and
                    precision.
                </Text>

                <Text>
                    <Heading as="h2" size={'md'} pb='2'>All-Encompassing Auto Glass Services in Gilbert</Heading>
                    Auto glass issues can arise unexpectedly, but don't worry! Our services at
                    Auto Glass Repair Gilbert are always accessible. Day or night, we are just a phone call away. Reach
                    out to us at <Link href={telLink}>{phoneNumber}</Link> for prompt and reliable auto glass services,
                    available any time of the day in Gilbert.
                </Text>

                <Text>
                    <Heading as="h2" size={'md'} pb='2'>Your Trusted Auto Glass Provider in Gilbert</Heading>
                    Facing auto glass challenges in Gilbert, AZ? Your solution is just a call away at
                    Auto Glass Repair Gilbert. Remember our number for hassle-free service and immediate assistance. We
                    offer various payment options, ensuring our services are affordable and convenient for you. Whether
                    you need urgent repairs or routine maintenance, our <Link href={'/'}>auto
                    glass services</Link> are prepared to help.
                </Text>

                <Text>
                    <Heading as="h2" size={'md'} pb='2'>Customized Auto Glass Solutions in Gilbert</Heading>
                    At Auto Glass Repair Gilbert, we provide a range of services tailored for every Gilbert driver:
                    <UnorderedList>
                        <ListItem><Link href={"/windshield-repair"}>Windshield Repair</Link>: Efficient <a
                            href={"https://www.glendaleautoglasspros.com/"} target={"_blank"} rel={"noreferrer"}>windshield
                            repair</a> for chips and cracks, ensuring your safety on Gilbert's roads.</ListItem>
                        <ListItem><Link href={"/windshield-replacement"}>Windshield Replacement</Link>:
                            Comprehensive replacement services for all types of vehicles, assuring perfect fit and
                            clarity.</ListItem>
                        <ListItem><Link href={"/mobile-auto-glass"}>Mobile Auto Glass Services</Link>:
                            Convenient on-site repairs and replacements, right at your doorstep in Gilbert,
                            AZ.</ListItem>
                        <ListItem><Link href={"/rear-window-repair"}>Rear Window Repair and Replacement</Link>:
                            Expert solutions for your vehicle's rear window, offering both repair and replacement
                            options.</ListItem>
                        <ListItem><Link href={"/side-window-repair"}>Side Window Repair and Replacement</Link>:
                            Skilled service for side window damages, ensuring your vehicle’s safety and
                            appearance.</ListItem>
                        <ListItem><Link href={"/adas-calibration"}>ADAS Calibration</Link>: Accurate calibration
                            of Advanced Driver Assistance Systems following windshield services, for enhanced road
                            safety.</ListItem>
                    </UnorderedList>
                    Rely on us at Auto Glass Repair Gilbert for personalized auto glass solutions in Gilbert.
                </Text>

                <Text>
                    <Heading as="h2" size={'md'} pb='2'>High-Quality Service Without Overspending</Heading>
                    At Auto Glass Repair Gilbert, we are committed to providing exceptional service without
                    overcharging. Expect transparent pricing with no hidden fees – just fair, honest rates that
                    demonstrate our dedication to quality and customer satisfaction in Gilbert.
                </Text>

                <Text>
                    <Heading as="h2" size={'md'} pb='2'>Experienced Technicians Ready to Serve You</Heading>
                    Our team consists of skilled technicians experienced in all facets of auto glass repair and
                    replacement. Using the best materials and modern techniques, we guarantee superior results for every
                    vehicle in Gilbert.
                </Text>

                <Text>
                    <Heading as="h2" size={'md'} pb='2'>Committed to Your Safety and Satisfaction in Gilbert</Heading>
                    Your safety is our utmost priority at Auto Glass Repair Gilbert. We strive for excellence in every
                    task, ensuring all repairs and replacements meet rigorous standards. Count on us for dependable and
                    timely services in Gilbert, AZ.
                </Text>

                <Text>
                    <Heading as="h2" size={'md'} pb='2'>Auto Glass Repair Gilbert: Your Reliable Partner in
                        Gilbert</Heading>
                    If you’re dealing with auto glass issues in Gilbert, AZ, just reach out to
                    Auto Glass Repair Gilbert. Call us at (480) 900-7010 for swift support. We're eager to provide a
                    free quote and show you why we are Gilbert’s preferred choice for auto glass services. Our range of
                    payment options ensures our services are accessible and stress-free for all.
                </Text>

                <Button as={'a'} href={telLink} bg={'#17b644'} fontSize={{base: '20px', md: '23px'}}
                        color={'white'} fontWeight={'bold'}
                        _hover={{bg: '#06c03b', color: 'white', cursor: 'pointer'}}
                        p={'16px 30px'} borderRadius={0} mt={'2rem'}
                        lineHeight={'26px'}
                        minH={'66px'}
                        width={'100%'}
                        maxW={'400px'}><i className="fa fa-phone" aria-hidden="true"/>&nbsp;&nbsp;Call for <Hide
                    below='md'>Immediate</Hide> Assistance</Button>
            </Box>
        ],
        footerHeading: 'Contact Auto Glass Repair Gilbert',
        footerText: <span>For top-notch auto glass services in Gilbert and surrounding areas, get in touch with us today.
            Call or use our online contact form for a free quote and experience the difference at
            Auto Glass Repair Gilbert.</span>,
        footerText2: 'Ensuring Safety and Quality in Every Repair and Replacement',
        servicesHeader: 'Our Extensive Range of Auto Glass Services',
        servicesSubtitle: () => <Text color="muted" fontSize={{base: 'lg', md: 'xl'}}>Discover our <Link
            href={'/services'}>services</Link> – from detailed windshield repair to full replacements,
            Auto Glass Repair Gilbert is your trusted source for all auto glass needs in Gilbert, AZ.</Text>,
    },
    careers: {
        url: "https://www.autoglassrepairgilbert.com/careers/",
        metaData: {
            title: "Join Our Dynamic Team - Auto Glass Repair Careers in Gilbert, AZ",
            description: "Embark on a rewarding career in auto glass repair with Auto Glass Repair Gilbert. We're inviting passionate individuals to join our skilled team in Gilbert, AZ. Uncover your potential with us."
        },
        h1: "We're Hiring - Shape Your Future with Auto Glass Repair Gilbert",
        h2: [
            "Why Choose a Career at Auto Glass Repair Gilbert?",
            "Discover Our Latest Auto Glass Technician Openings",
            "Our Commitment to Employee Development and Satisfaction"
        ],
        headingText: {
            whyJoin: "Join a workplace where your talent in auto glass repair and customer service is highly valued. Become a part of a team dedicated to excellence in vehicle glass repair and windshield replacement services in Gilbert, AZ.",
            openings: "Explore our current job openings for proficient auto glass technicians and find a role that matches your skills and career aspirations.",
            commitment: "At Auto Glass Repair Gilbert, we nurture a culture of growth and support, ensuring every team member has opportunities to excel. Your professional growth is our top priority."
        },
        content: [
            <Text>Welcome to the Careers page at <Link href={'/'}>Auto Glass Repair Gilbert</Link>, your portal to a
                thriving career in the auto glass repair sector. We're more than just a team; we're a family committed
                to delivering outstanding windshield repair and replacement services in Gilbert, AZ.</Text>,
            <Text>At <Link href={'/'}>Auto Glass Repair Gilbert</Link>, we believe our success stems from our people. We
                seek dedicated professionals eager to provide superior auto glass services. Regardless of whether you're
                an experienced technician or eager to learn, we offer an environment where your skills are valued and
                nurtured.</Text>,
            <Text>Our team enjoys a collaborative culture that fosters both personal and professional growth. We provide
                extensive training, competitive salaries, and opportunities to work with the latest tools and
                technologies in the auto glass industry. Our aim is to create a safe, fulfilling, and enjoyable
                workplace for everyone.</Text>,
            <Text>Being an integral part of the Gilbert community, we pride ourselves on serving our customers with
                integrity and professionalism. This commitment to excellence extends to our team members, whom we
                support with a positive work environment, mutual respect, and career advancement opportunities.</Text>,
            <Text>Ready to start your career with a leading auto glass repair provider in <a
                href='https://en.wikipedia.org/wiki/Gilbert,_Arizona'>Gilbert, AZ</a>? Check out our current job
                openings and find your fit in our expanding team. From on-site repairs to customer service positions,
                there's a place for you at Auto Glass Repair Gilbert.</Text>,
            <Text><a href='https://www.autoglassrepairgilbert.com/careers/'>Find your next career opportunity</a> with
                us. Join a team that makes a difference with every repair and every satisfied customer. At <Link
                    href={'/'}>Auto Glass Repair Gilbert</Link>, we're more than a business; we're a community. Apply
                now and steer your career towards success.</Text>,
        ]
    },
    about: {
        url: "https://www.autoglassrepairgilbert.com/about/",
        metaData: {
            title: "About Us - Auto Glass Repair Gilbert | Professional Auto Glass Services in Gilbert, AZ",
            description: "Discover more about Auto Glass Repair Gilbert, your trusted experts for auto glass repair and replacement in Gilbert, AZ."
        },
        h1: "About Auto Glass Repair Gilbert",
        h2: [
            "Leading Auto Glass Repair Services in Gilbert, AZ",
            "Dedicated to Excellence and Customer Satisfaction",
            "Why Auto Glass Repair Gilbert is Your Top Choice for Auto Glass Needs"
        ],
        headingText: {
            commitment: "We are committed to providing superior auto glass repair and replacement services, ensuring each job adheres to the highest standards of quality and safety.",
            team: "Our team of skilled technicians brings extensive experience and expertise to every auto glass challenge, utilizing advanced techniques and tools for the best results.",
            services: "Offering a comprehensive suite of auto glass services, including windshield repair, full windshield replacement, mobile services, and more. Our emphasis on quality and efficiency positions us as the first choice for drivers in Gilbert, AZ."
        },
        services: [
            {
                id: "0",
                title: 'Windshield Repair',
                href: '/windshield-repair',
                icon: BiWrench,
                description: <Text>Specialized repair services for chips and cracks, enhancing your windshield’s
                    durability and clarity. We use advanced resin injection techniques for flawless results.</Text>
            },
            {
                id: "1",
                title: 'Windshield Replacement',
                href: '/windshield-replacement',
                icon: FaCarCrash,
                description: <Text>Complete windshield replacement services for all vehicle types. We employ
                    high-quality glass and adhesives, assuring a perfect fit and lasting durability.</Text>
            },
            {
                id: "2",
                title: 'Mobile Auto Glass Services',
                href: '/mobile-auto-glass-services',
                icon: MdDirectionsCar,
                description: <Text>Our mobile auto glass repair and replacement services offer the ultimate convenience.
                    Ideal for busy schedules or in emergency situations within Gilbert, AZ.</Text>
            },
            {
                id: "3",
                title: 'Rear Window Repair and Replacement',
                href: '/rear-window-repair',
                icon: GiCarDoor,
                description: <Text>Expert services for rear window issues, offering both repair and complete replacement
                    options, tailored to your vehicle’s specific needs.</Text>
            },
            {
                id: "4",
                title: 'Side Window Repair and Replacement',
                href: '/side-window-repair',
                icon: AiOutlineCar,
                description: <Text>Efficient handling of side window damages. From minor repairs to full replacements,
                    we ensure your vehicle’s safety and aesthetic integrity.</Text>
            },
            {
                id: "5",
                title: 'ADAS Calibration',
                href: '/adas-calibration',
                icon: RiDashboardLine,
                description: <Text>Precise calibration of Advanced Driver Assistance Systems following any windshield
                    service, ensuring your safety features are functioning correctly.</Text>
            }
        ],
    },
    services: {
        url: 'https://www.autoglassrepairgilbert.com/services/',
        metaData: {
            title: 'Auto Glass Repair and Replacement Services in Gilbert, AZ | Auto Glass Repair Gilbert',
            description: 'Explore our extensive range of auto glass services in Gilbert, AZ. From windshield repair to ADAS calibration, we cater to all your auto glass requirements.'
        },
        h1: 'Expert Auto Glass Services in Gilbert, AZ',
        h2: 'Our Professional Auto Glass Repair and Replacement Services',
        subtext: 'At Auto Glass Repair Gilbert, we provide a variety of auto glass services designed to meet your specific needs, ensuring safety and quality in every job.',
        footerHeading: 'Get Your Auto Glass Repaired with Us',
        footerText2: () => <span>For top-quality auto glass repair and replacement services, contact us at <Link
            href={telLink}>{phoneNumberWithDashes}</Link>. Depend on us for quick, reliable, and efficient service in
            Gilbert.</span>,
        footerText: () => <span>Remember, your safety is our priority. With Auto Glass Repair Gilbert, you're in expert
            hands. Contact us today!</span>,
        content: <Box>
            <Stack spacing={5}>
                <Heading as="h2" size="xl">Comprehensive Auto Glass Solutions in Gilbert, AZ</Heading>
                <Text>Handling everything from a minor windshield crack to a complete glass replacement, our certified
                    technicians are equipped to address all types of auto glass issues in Gilbert.</Text>

                <Heading as="h3" size="lg">Why Trust Auto Glass Repair Gilbert?</Heading>
                <List spacing={3}>
                    <ListItem><Text><b>Quality Materials:</b> We use only premium materials and glass for durable and
                        long-lasting repairs and replacements.</Text></ListItem>
                    <ListItem><Text><b>Advanced Technology:</b> Employing the latest tools and techniques for precise
                        and efficient auto glass service.</Text></ListItem>
                    <ListItem><Text><b>Experienced Technicians:</b> Our team is highly skilled and experienced in a
                        range of auto glass services, ensuring top-quality results.</Text></ListItem>
                    <ListItem><Text><b>Customer-Centric Service:</b> We prioritize your convenience and satisfaction in
                        every service we provide in Gilbert.</Text></ListItem>
                </List>

                <Heading as="h3" size="lg">A Range of Auto Glass Services</Heading>
                <List spacing={3}>
                    <ListItem>
                        <Text>
                            <Link href="/windshield-repair" style={{fontWeight: "bold"}}>Windshield Repair and
                                Replacement:</Link> Expert solutions for everything from minor chips to major cracks and
                            full windshield replacements.
                        </Text>
                    </ListItem>
                    <ListItem>
                        <Text>
                            <Link href="/mobile-auto-glass" style={{fontWeight: "bold"}}>Mobile Auto Glass
                                Services:</Link> On-site repairs and replacements throughout Gilbert, AZ, for your
                            convenience.
                        </Text>
                    </ListItem>
                    <ListItem>
                        <Text>
                            <Link href="/rear-and-side-window-repair" style={{fontWeight: "bold"}}>Rear and Side
                                Window Solutions:</Link> Comprehensive services addressing all rear and side window
                            issues, assuring the integrity and safety of your vehicle.
                        </Text>
                    </ListItem>
                    <ListItem>
                        <Text>
                            <Link href="/adas-calibration" style={{fontWeight: "bold"}}>ADAS
                                Calibration:</Link> Accurate calibration of your vehicle's advanced safety features
                            following any windshield service.
                        </Text>
                    </ListItem>
                </List>
            </Stack>
        </Box>,
    },
    contact: {
        url: 'https://www.autoglassrepairgilbert.com/contact/',
        metaData: {
            title: 'Contact Us - Auto Glass Repair Gilbert | Your Auto Glass Experts in Gilbert, AZ',
            description: 'Get in touch with Auto Glass Repair Gilbert for all your auto glass repair needs in Gilbert, AZ. Contact us today for a free quote and excellent service.'
        },
        h1: 'Contact Auto Glass Repair Gilbert',
        subtitle: 'Connect with our expert team for fast and reliable auto glass repair services in Gilbert, AZ.',
        sectionHeading: 'Here to Assist You with Your Auto Glass Needs',
        signUpDescription: 'Complete our online form to get in touch, and we will respond promptly with a solution tailored to your needs in Gilbert.',
        feedbackText: 'Your feedback and inquiries are important to us. Feel free to contact us with any questions or to schedule a service in Gilbert.',
        closingText: <span>For expert auto glass repair in Gilbert, reach out to us for a free estimate.</span>,
        valueProps: [
            {
                title: 'Expertise in Auto Glass Repair',
                description: 'Our team in Gilbert, AZ, brings years of specialized experience in auto glass repair, ensuring each job is done with utmost precision and expertise.'
            },
            {
                title: 'Responsive Customer Service',
                description: 'We pride ourselves on our responsive customer service. Whether you have a query or need urgent assistance, our team is ready to provide timely and helpful responses.'
            },
            {
                title: 'State-of-the-Art Equipment',
                description: 'We use the latest technology and equipment in all our repair and replacement services, guaranteeing high-quality work that stands the test of time.'
            },
            {
                title: 'Mobile Repair Services',
                description: 'For added convenience, we offer mobile auto glass repair services in Gilbert, AZ. We come to your location, providing fast and efficient service on the go.'
            },
            {
                title: 'Safety and Quality Assurance',
                description: 'Safety is our top priority. We adhere to strict quality standards to ensure every repair or replacement enhances the safety of your vehicle.'
            },
            {
                title: 'Satisfaction Guaranteed',
                description: 'Customer satisfaction is at the heart of what we do. We strive to exceed expectations with each service, ensuring you’re completely satisfied with the results.'
            }
        ]
    },
    blog: {
        url: 'https://www.autoglassrepairgilbert.com/blog/',
        metaData: {
            title: 'Auto Glass Repair Blog | Tips & Insights | Auto Glass Repair Gilbert',
            description: 'Stay informed with the latest tips, insights, and trends in auto glass repair from our experts, serving Gilbert, AZ.'
        },
        h1: 'Auto Glass Repair Blog - Tips, Insights, and More',
        h2: 'Latest Articles and Advice',
        heading: 'Explore our blog for valuable articles on auto glass maintenance, repair techniques, and the latest industry news. Get the best practices and advice from our experienced technicians in Gilbert.',
        posts: posts // List of blog posts relevant to Gilbert, AZ
    },
    windshieldRepair: {
        url: 'https://www.autoglassrepairgilbert.com/windshield-repair',
        metaData: {
            title: 'Windshield Repair Services in Gilbert, AZ | Auto Glass Repair Gilbert',
            description: 'Expert windshield repair services in Gilbert, AZ. Efficiently fix chips and cracks with Auto Glass Repair Gilbert.'
        },
        h1: 'Windshield Repair Services',
        subtitle: 'Quick and Reliable Windshield Repair in Gilbert, AZ',
        body: <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Effective Solutions for Windshield Damage in Gilbert</Heading>
                <Text>Our windshield repair services in Gilbert include:</Text>
                <UnorderedList spacing={3}>
                    <ListItem>Chip and Crack Repairs: Using advanced techniques to restore your windshield's integrity
                        and clarity in the Gilbert climate.</ListItem>
                    <ListItem>Long-Lasting Results: Ensuring repairs are durable and prevent further damage under
                        Gilbert’s unique conditions.</ListItem>
                    <ListItem>Quick Service: Offering prompt repairs to get you back on the roads of Gilbert safely and
                        quickly.</ListItem>
                </UnorderedList>
                <Text>For efficient windshield repair solutions in Gilbert, AZ, contact
                    Auto Glass Repair Gilbert.</Text>
            </Stack>
        </Box>
    },
    mobileAutoGlassServices: {
        url: 'https://www.autoglassrepairgilbert.com/mobile-auto-glass-services',
        metaData: {
            title: 'Mobile Auto Glass Services in Gilbert, AZ | Auto Glass Repair Gilbert',
            description: 'Convenient mobile auto glass repair and replacement services in Gilbert, AZ. Top-quality service at your location from Auto Glass Repair Gilbert.'
        },
        h1: 'Mobile Auto Glass Services',
        subtitle: 'Convenient On-the-Spot Auto Glass Repair and Replacement in Gilbert',
        body: <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Mobile Service Meets Quality in Gilbert</Heading>
                <Text>Our mobile auto glass services in Gilbert include:</Text>
                <UnorderedList spacing={3}>
                    <ListItem>On-Site Repairs: Providing professional services at your location in Gilbert for maximum
                        convenience.</ListItem>
                    <ListItem>Comprehensive Services: From chip repairs to full windshield replacements, available
                        wherever you are in Gilbert.</ListItem>
                    <ListItem>Flexible Scheduling: Adapting to your busy schedule in Gilbert with timely and efficient
                        service.</ListItem>
                </UnorderedList>
                <Text>For mobile auto glass services in Gilbert, AZ, rely on the convenience and quality of
                    Auto Glass Repair Gilbert.</Text>
            </Stack>
        </Box>
    },
    windshieldReplacement: {
        url: 'https://www.autoglassrepairgilbert.com/windshield-replacement',
        metaData: {
            title: 'Windshield Replacement Services in Gilbert, AZ | Auto Glass Repair Gilbert',
            description: 'High-quality windshield replacement services in Gilbert, AZ. Ensure the best fit and durability with Auto Glass Repair Gilbert.'
        },
        h1: 'Windshield Replacement Services',
        subtitle: 'Top-Quality Windshield Replacement for All Vehicle Types in Gilbert',
        body: <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Expert Windshield Replacement in Gilbert</Heading>
                <Text>Our windshield replacement services in Gilbert include:</Text>
                <UnorderedList spacing={3}>
                    <ListItem>Quality Glass Selection: Offering a wide array of high-quality glass options suitable for
                        all vehicle models, specific to Gilbert’s environment.</ListItem>
                    <ListItem>Precision Installation: Ensuring a perfect fit and leak-proof sealing for durable
                        performance in Gilbert's climate.</ListItem>
                    <ListItem>Safety Compliance: Replacing windshields following safety standards for the protection of
                        Gilbert drivers.</ListItem>
                </UnorderedList>
                <Text>For professional windshield replacement in Gilbert, AZ, choose Auto Glass Repair Gilbert.</Text>
            </Stack>
        </Box>
    },
    rearWindowRepair: {
        url: 'https://www.autoglassrepairgilbert.com/rear-window-repair',
        metaData: {
            title: 'Rear Window Repair Services in Gilbert, AZ | Auto Glass Repair Gilbert',
            description: 'Expert rear window repair services for all vehicle types in Gilbert, AZ. Rely on Auto Glass Repair Gilbert for reliable and efficient service.'
        },
        h1: 'Rear Window Repair Services',
        subtitle: 'Efficient Repair Solutions for Your Vehicle’s Rear Window in Gilbert',
        body: <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Specialized Rear Window Care in Gilbert</Heading>
                <Text>Our rear window repair services in Gilbert include:</Text>
                <UnorderedList spacing={3}>
                    <ListItem>Damage Assessment: Conducting thorough examinations to determine the best repair approach
                        for vehicles in Gilbert.</ListItem>
                    <ListItem>Quality Repairs: Using the finest materials and techniques suitable for Gilbert’s
                        conditions for lasting results.</ListItem>
                    <ListItem>Safety Focus: Ensuring all repairs meet safety standards, keeping Gilbert drivers and
                        passengers protected.</ListItem>
                </UnorderedList>
                <Text>Choose Auto Glass Repair Gilbert for top-tier rear window repair services in Gilbert, AZ.</Text>
            </Stack>
        </Box>
    },
    sideWindowRepair: {
        url: 'https://www.autoglassrepairgilbert.com/side-window-repair',
        metaData: {
            title: 'Side Window Repair Services in Gilbert, AZ | Auto Glass Repair Gilbert',
            description: 'Professional side window repair for all vehicles in Gilbert, AZ. Fast and efficient service from Auto Glass Repair Gilbert.'
        },
        h1: 'Side Window Repair Services',
        subtitle: 'Reliable Side Window Repairs for All Vehicles in Gilbert',
        body: <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Comprehensive Repair for Side Windows in Gilbert</Heading>
                <Text>Our side window repair services in Gilbert include:</Text>
                <UnorderedList spacing={3}>
                    <ListItem>Damage Repair: Addressing everything from minor chips to complete window damage, tailored
                        for vehicles in Gilbert.</ListItem>
                    <ListItem>Swift Service: Providing quick and effective repairs to restore your vehicle to optimal
                        condition in Gilbert.</ListItem>
                    <ListItem>Quality Assurance: Utilizing high-quality materials for durable and reliable repairs in
                        Gilbert’s environment.</ListItem>
                </UnorderedList>
                <Text>For dependable side window repair in Gilbert, AZ, turn to the expertise of
                    Auto Glass Repair Gilbert.</Text>
            </Stack>
        </Box>
    },
    ADASCalibration: {
        url: 'https://www.autoglassrepairgilbert.com/adas-calibration',
        metaData: {
            title: 'ADAS Calibration Services in Gilbert, AZ | Auto Glass Repair Gilbert',
            description: 'Ensure your vehicle’s safety features work correctly with our ADAS calibration services in Gilbert, AZ.'
        },
        h1: 'ADAS Calibration Services',
        subtitle: 'Accurate Calibration for Advanced Driver Assistance Systems in Gilbert',
        body: <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Ensuring Vehicle Safety Features in Gilbert</Heading>
                <Text>Our ADAS calibration services in Gilbert include:</Text>
                <UnorderedList spacing={3}>
                    <ListItem>Accurate Calibration: Using state-of-the-art tools to ensure precise calibration of your
                        vehicle’s ADAS features in Gilbert.</ListItem>
                    <ListItem>Post-Replacement Calibration: Providing essential calibration services following
                        windshield replacement, crucial for Gilbert drivers.</ListItem>
                    <ListItem>Experienced Technicians: Our skilled professionals are trained in the latest ADAS
                        technology, catering to vehicles in Gilbert.</ListItem>
                </UnorderedList>
                <Text>For expert ADAS calibration services in Gilbert, AZ, trust the professionals at
                    Auto Glass Repair Gilbert.</Text>
            </Stack>
        </Box>,
    },
    testimonials: {
        h1: 'Discover Why Our Customers Trust Us',
        h2: 'Testimonials',
        heading: 'From windshield repairs to replacements, Auto Glass Repair Gilbert is the top-rated choice for all your auto glass needs in Gilbert, AZ',
        testimonials: [
            {
                avatarUrl: 'https://i.pravatar.cc/150?u=a042581f4e29026704d',
                name: 'Emily R.',
                title: 'Gilbert, AZ',
                quote: '"I had an excellent experience with Auto Glass Repair Gilbert. They efficiently repaired my windshield, and it\'s as good as new. The technician was skilled, and the pricing was very fair."'
            },
            {
                avatarUrl: 'https://i.pravatar.cc/150?u=a0425d',
                name: 'Mark T.',
                title: 'Gilbert, AZ',
                quote: '"A rock damaged my windshield, and Auto Glass Repair Gilbert was a lifesaver. Their mobile service came right to me, and the repair was flawless. I highly recommend them for their convenience and quality work."'
            },
            {
                avatarUrl: 'https://i.pravatar.cc/150?u=a0804d',
                name: 'Sara P.',
                title: 'Gilbert, AZ',
                quote: '"Whenever I have auto glass issues, I turn to Auto Glass Repair Gilbert. They\'ve replaced my car’s side window, and the entire process was effortless. The quality of service and customer care is outstanding."'
            },
        ]
    },
    '404': {
        metaData: {
            title: 'Page Not Found - Gilbert’s Auto Glass Experts',
            description: 'The page you’re looking for isn’t available, but our comprehensive auto glass services in Gilbert, AZ, are always here for you.'
        },
        h1: '404 - Page Not Found',
        subtitle: 'Can’t find what you need? We’re here to help with all your auto glass needs in Gilbert.',
        p: 'The page you’re seeking is missing, but our range of auto glass services in Gilbert, AZ, is just a click away. Navigate back to our homepage or explore our services for all your auto glass requirements.'
    },
}