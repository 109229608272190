export const posts = [
    {
        id: '1',
        title: 'Understanding Windshield Repair vs. Replacement in Gilbert',
        metaTitle: 'Gilbert Windshield Repair or Replacement: Making the Right Choice',
        excerpt: 'Learn how to determine whether your windshield in Gilbert needs a repair or a full replacement, considering local climate and road conditions.',
        slug: 'windshield-repair-vs-replacement-gilbert',
        tags: ['windshield repair', 'windshield replacement', 'Gilbert auto glass'],
        metaDescription: 'Deciding between windshield repair and replacement in Gilbert can be challenging. Read our guide to make an informed decision for your vehicle’s windshield.',
        lastModified: new Date('2023-12-03').toISOString()
    },
    {
        id: '2',
        title: 'The Importance of Professional ADAS Calibration in Gilbert',
        metaTitle: 'Why Professional ADAS Calibration Matters in Gilbert, AZ',
        excerpt: 'Discover the significance of ADAS calibration after windshield replacement in Gilbert and how it impacts your vehicle’s safety features.',
        slug: 'professional-adas-calibration-importance-gilbert',
        tags: ['ADAS calibration', 'Gilbert auto glass safety', 'windshield replacement'],
        metaDescription: 'ADAS calibration is crucial for vehicle safety in Gilbert, AZ. Learn why professional calibration is essential after windshield replacement.',
        lastModified: new Date('2023-12-03').toISOString()
    },
    {
        id: '3',
        title: 'Mobile Auto Glass Services in Gilbert: Convenience and Efficiency',
        metaTitle: 'The Benefits of Mobile Auto Glass Repair and Replacement in Gilbert',
        excerpt: 'Explore the advantages of mobile auto glass services in Gilbert, offering convenience and efficiency without compromising on quality.',
        slug: 'mobile-auto-glass-services-benefits-gilbert',
        tags: ['mobile auto glass', 'Gilbert convenience', 'efficient service'],
        metaDescription: 'Mobile auto glass services bring convenience to your doorstep in Gilbert. Discover how these services can save you time and provide efficient repairs.',
        lastModified: new Date('2023-12-03').toISOString()
    },
    {
        id: '4',
        title: 'Tips for Maintaining Your Auto Glass in Gilbert’s Extreme Weather',
        metaTitle: 'Maintaining Auto Glass in Gilbert’s Extreme Weather Conditions',
        excerpt: 'Learn how to protect and maintain your auto glass in Gilbert during extreme weather conditions to ensure safety and prolong its lifespan.',
        slug: 'maintaining-auto-glass-extreme-weather-gilbert',
        tags: ['auto glass maintenance', 'Gilbert extreme weather', 'vehicle safety'],
        metaDescription: 'Extreme weather in Gilbert can pose a threat to your vehicle’s auto glass. Discover essential tips for maintaining your auto glass in harsh weather conditions.',
        lastModified: new Date('2023-12-03').toISOString()
    }
]
