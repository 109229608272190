import React from "react";
import {Heading, Link, List, ListItem, Stack, Text} from "@chakra-ui/react";

export const BlogPost2 = () => {
    return (
        <Stack spacing={3}>
            <Text>
                Understanding the importance of ADAS calibration after windshield replacement is crucial for vehicle
                owners in Gilbert, AZ. This process ensures that your vehicle’s safety features function accurately.
            </Text>
            <Heading as="h3" fontSize={'xl'}>Why Professional ADAS Calibration Matters in Gilbert</Heading>
            <List styleType="disc">
                <ListItem>Accuracy is Key: Proper calibration of ADAS ensures that safety systems like automatic braking
                    and lane-keeping assist work correctly.</ListItem>
                <ListItem>Post-Replacement Necessity: After a windshield replacement, recalibration is essential to
                    align the sensors and cameras accurately.</ListItem>
                <ListItem>Enhancing Safety: Accurate ADAS calibration plays a critical role in maintaining the
                    effectiveness of your vehicle's safety features.</ListItem>
            </List>
            <Text>Ensure your vehicle's safety systems are accurately calibrated in Gilbert by reaching out to the
                experts at <Link href="/">AutoGlassRepairGilbert.com</Link>.</Text>
        </Stack>
    )
}