import {
    Box,
    Button,
    Container,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
    Text,
    Textarea,
    theme,
    useBreakpointValue
} from "@chakra-ui/react";
import * as React from "react";
import {EmailIcon, PhoneIcon} from "@chakra-ui/icons";
import {FaUserAlt} from "react-icons/fa";

export const SignUpForm = ({description, maxW}) => {
    return (
        <Box>
            <Container p={0} pr={{base: 0, md: 20}} px={{base: 0, md: 0}}>
                <Stack spacing="8">
                    <Stack spacing="6">
                        {/*<Logo />*/}
                        <Stack spacing="3">
                            <Heading size={useBreakpointValue({base: "md", md: "lg"})}>Get <Text color={'#FFA903'}
                                                                                                 as="span">FREE</Text> estimate!</Heading>
                            <Text color="muted">{description || 'Provide some information and get a quote today'}</Text>
                        </Stack>
                    </Stack>

                    <Stack spacing="6">
                        <form name="contact" method="POST" action={"/?index"} data-netlify="true">
                            <input type="hidden" name="form-name" value="contact"/>
                            <Stack spacing="4">
                                <FormControl isRequired>
                                    <FormLabel htmlFor="name">Name</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement pointerEvents='none'>
                                            <FaUserAlt style={{color: theme.colors.gray['300']}}/>
                                        </InputLeftElement>
                                        <Input id="name" type="text" name="name"/>
                                    </InputGroup>
                                </FormControl>
                                <FormControl isRequired>
                                    <FormLabel htmlFor="email">Email</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement pointerEvents='none'>
                                            <EmailIcon color='gray.300'/>
                                        </InputLeftElement>
                                        <Input id="email" type="email" name="email"/>
                                    </InputGroup>
                                </FormControl>
                                <FormControl isRequired>
                                    <FormLabel htmlFor="phone">Phone number</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement pointerEvents='none'>
                                            <PhoneIcon color='gray.300'/>
                                        </InputLeftElement>
                                        <Input id="phone" type="tel" name="phone"/>
                                    </InputGroup>
                                </FormControl>
                                <FormControl>
                                    <FormLabel htmlFor="comment">Comment</FormLabel>
                                    <Textarea id="comment" name="comment"/>
                                </FormControl>
                                <HStack spacing="1">
                                    <Button type="submit" size="lg" bg={'#FFA903'} _hover={{bg: 'green.600'}}
                                            color={'white'} fontWeight="bold" mt="2">
                                        Submit
                                    </Button>
                                </HStack>
                            </Stack>
                        </form>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
}
